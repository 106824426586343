html, body, #root, .app {
    height: 100%;
    max-width: 1320px;
    margin: 0px auto;
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 50px;
}

.content {
    flex: 1 0 auto;
    margin: 50px 0;
}
